import { FooterColumn, FooterColumnGroup, footerNavigationItems } from '@/constants/footerNavigationConstants';
import { NAVIGATION_TRIGGER } from '@/constants/navigation';
import { SEARCH_CONTEXT } from '@/features/searchV2/constants/tracking';
import { isMobile, isSistaminuten, url } from '@/helpers';
import { getScreenName, handleNavItemClicked } from '@/services/navigationServices';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { PaymentLogosIcons } from './FooterBottom';
import { FooterExternalLinks } from './FooterExternalLinks';

const FooterNavigation = () => {
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch();
  const location = useLocation();
  const isListPage = routeMatch.path === '/:q/:location';
  const handleClick = handleNavItemClicked(
    dispatch,
    isListPage ? 'search_results' : getScreenName(url.getScreenNameFromUrl(location.pathname)),
    NAVIGATION_TRIGGER.FOOTER,
  );

  interface ColumnGroup {
    [key: string]: FooterColumn[];
  }

  const navItems = footerNavigationItems(isSistaminuten(), isListPage);
  let groups = {} as ColumnGroup;
  navItems.forEach((item) => {
    groups[item.group] = groups[item.group] || ([] as FooterColumn[]);
    groups[item.group].push(item);
  });

  return !isSistaminuten() ? (
    <>
      <div key={0} className={`block md:hidden ${isListPage ? '' : 'lg:block'}`}>
        <FooterExternalLinks />
        <div className="flex flex-wrap items-center justify-between pt-4 md:hidden">
          {PaymentLogosIcons.map((item) => item)}
        </div>
      </div>
      {Object.entries(groups).map(([key, group]) => (
        <div key={key} className={group[0].className}>
          {group.map((column, k) => (
            <nav key={k + 1} className={column.className}>
              <h2
                className={`text-black-600 text-m whitespace-normal font-semibold lg:whitespace-pre-wrap xl:whitespace-normal ${
                  k !== 0 ? 'pt-6' : ''
                }`}>
                {column.title}
              </h2>
              <ul
                className={`flex flex-col flex-wrap gap-3 py-3 ${
                  column.group === FooterColumnGroup.PopularMix ? 'max-h-[400px]' : ''
                }`}>
                {column.links.map((link, key) => {
                  const clickProps = link.identifier ? { onClick: handleClick(link.identifier) } : {};

                  let isSerpLink: boolean = undefined;

                  switch (column.group) {
                    case FooterColumnGroup.PopularBeauty:
                    case FooterColumnGroup.PopularMix:
                    case FooterColumnGroup.PopularHealth:
                    case FooterColumnGroup.Sistaminutentider:
                      isSerpLink = true;
                      break;
                    default:
                      isSerpLink = false;
                  }

                  return (
                    <li
                      key={key}
                      className={`flex-1 ${
                        column.group === FooterColumnGroup.PopularMix
                          ? 'whitespace-pre-wrap'
                          : 'whitespace-normal md:whitespace-pre-wrap'
                      } ${isListPage && !isMobile() ? '2xl:whitespace-normal' : 'xl:whitespace-normal'}`}>
                      {link.link ? (
                        <Link
                          className=" text-black-900 hover:text-black-900 text-m"
                          to={{
                            pathname: link.url,
                            ...(isSerpLink && { state: { searchContext: SEARCH_CONTEXT.FOOTER_CATEGORY } }),
                          }}
                          {...clickProps}>
                          {link.title}
                        </Link>
                      ) : (
                        <a href={link.url} className=" text-black-900 hover:text-black-900 text-m" {...clickProps}>
                          {link.title}
                        </a>
                      )}
                    </li>
                  );
                })}
              </ul>
            </nav>
          ))}
        </div>
      ))}
      <div className={`hidden md:block ${isListPage ? '' : 'lg:hidden'} w-full`}>
        <FooterExternalLinks displayRow={true} />
      </div>
    </>
  ) : (
    <>
      {navItems.map((column, k) => (
        <div key={k} className="last-of-type:mr-10">
          <h4 className="text-black-900 mb-2 font-semibold">{column.title}</h4>
          <ul className="flex flex-col gap-[6px]">
            {column.links.map((link, key) => {
              const clickProps = link.identifier ? { onClick: handleClick(link.identifier) } : {};
              return (
                <li key={key} className="flex-1">
                  {link.link ? (
                    <Link className="text-black-900 hover:text-black-900" to={link.url} {...clickProps}>
                      {link.title}
                    </Link>
                  ) : (
                    <a href={link.url} className="text-black-900 hover:text-black-900" {...clickProps}>
                      {link.title}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </>
  );
};

export default FooterNavigation;
