import { getGeoLocation } from '@/helpers';
import { useEffect, useReducer } from 'react';
import { getLocationSuggestionFromSearchHistory, getSuggestionsFromSearchValue } from './LocationAutoSuggest.helpers';
import {
  LocationAutoSuggestAction,
  LocationAutoSuggestManagerProps,
  LocationAutoSuggestState,
  LocationSuggestion,
} from './LocationAutoSuggest.types';

const locationAutosuggestReducer = (
  state: LocationAutoSuggestState,
  action: LocationAutoSuggestAction,
): LocationAutoSuggestState => {
  switch (action.type) {
    case 'ON_INPUT_CHANGE': {
      return { ...state, locationValue: action.payload };
    }

    case 'ON_SUGGESTION_LOADED': {
      return { ...state, ...action.payload };
    }

    case 'SET_SHOW_SUGGESTIONS': {
      return { ...state, showSuggestions: action.payload };
    }

    case 'FETCHING_USER_POSITION': {
      return { ...state, isFetchingUserPosition: action.payload };
    }
    default:
      return state;
  }
};

const useLocationAutoSuggestManager = ({
  initialState,
  onSelectLocationSuggestion,
}: LocationAutoSuggestManagerProps) => {
  const [state, dispatch] = useReducer(locationAutosuggestReducer, initialState);

  useEffect(() => {
    async function initLocationValueFromHistory() {
      const noDefaultLocation = sessionStorage.getItem('noDefaultLocation');

      if (initialState.locationValue || noDefaultLocation) {
        return;
      }

      const suggestion = await getLocationSuggestionFromSearchHistory();

      if (!suggestion) {
        return;
      }

      dispatch({ type: 'ON_INPUT_CHANGE', payload: suggestion.label });

      const position = (() => {
        if (suggestion.position?.lat && suggestion.position?.lon) {
          return `${suggestion.position.lat}-${suggestion.position.lon}`;
        }
        return '';
      })();

      const params = {
        location: suggestion.label,
        locationId: suggestion?.id,
        position,
      };

      onSelectLocationSuggestion(params);
    }
    initLocationValueFromHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (event) => {
    dispatch({ type: 'ON_INPUT_CHANGE', payload: event.target.value });
  };

  const handleOnFocus = () => {
    dispatch({ type: 'SET_SHOW_SUGGESTIONS', payload: true });
  };

  const handleOnBlur = (_, { highlightedSuggestion }) => {
    if (highlightedSuggestion === null) {
      dispatch({ type: 'SET_SHOW_SUGGESTIONS', payload: false });
    }
  };
  const handleOnKeyDown = (event: React.KeyboardEvent) => {};

  const handleOnSuggestionsFetchRequested = async ({ value, reason }) => {
    if (reason !== 'input-focused' && reason !== 'input-changed') {
      return;
    }

    const { hasHistory, storedSuggestions, suggestions } = await getSuggestionsFromSearchValue(value);

    dispatch({ type: 'ON_SUGGESTION_LOADED', payload: { suggestions, storedSuggestions, hasHistory } });
  };

  const handleOnSuggestionsClearRequested = (props) => {};

  const handleOnClearHistorySuggestion = (suggestion: LocationSuggestion) => {
    const HISTORY = 'history';
    const historyIndex = state.storedSuggestions.findIndex((suggestion) => suggestion.type === HISTORY);
    const currentHistorySuggestions = state.storedSuggestions[historyIndex];
    const updatedHistorySuggestions = currentHistorySuggestions.suggestions.filter(
      (currentSuggestion) => currentSuggestion.label !== suggestion.label,
    );

    const historySuggestions = updatedHistorySuggestions ?? [];
    const nonHistorySuggestions = state.suggestions.filter((suggestion) => suggestion.type !== HISTORY);
    const payload = {
      suggestions: [
        {
          suggestions: historySuggestions?.slice(0, 3),
          title: HISTORY,
          type: HISTORY,
        },
        ...nonHistorySuggestions,
      ],
      storedSuggestions: [
        {
          suggestions: historySuggestions,
          title: HISTORY,
          type: HISTORY,
        },
        ...nonHistorySuggestions,
      ],
      hasHistory: !!historySuggestions.length,
    };

    dispatch({ type: 'ON_SUGGESTION_LOADED', payload });
  };

  const handleOnUpdateUserLocation = (
    params: { location: string; locationId?: string; lat?: string; lon?: string },
    suggestionValue: string,
  ) => {
    dispatch({ type: 'SET_SHOW_SUGGESTIONS', payload: false });
    dispatch({ type: 'ON_INPUT_CHANGE', payload: suggestionValue });
    dispatch({ type: 'FETCHING_USER_POSITION', payload: false });
    onSelectLocationSuggestion(params);
  };

  const handleOnFetchAndSetUserLocation = async (suggestionValue: string) => {
    dispatch({ type: 'FETCHING_USER_POSITION', payload: false });

    getGeoLocation(
      (lat: string, lon: string) => {
        const params = { location: suggestionValue, locationId: undefined, lat, lon };
        handleOnUpdateUserLocation(params, suggestionValue);
      },
      (cached) => {},
      () => {
        // on loading
        dispatch({ type: 'FETCHING_USER_POSITION', payload: true });
      },
      () => {
        // on error
        dispatch({ type: 'FETCHING_USER_POSITION', payload: false });
      },
    );
  };

  const handleOnSuggestionSelected = async (event, { suggestion, suggestionValue }) => {
    if (event?.target?.id === 'remove_suggested') {
      // @TODO: do stuff
      return;
    }

    const params: { location: string; locationId?: string; lat?: string; lon?: string } = {
      location: suggestion.label,
      locationId: suggestion?.id,
    };

    if (suggestion.type === 'geolocation') {
      handleOnFetchAndSetUserLocation(suggestionValue);
      dispatch({ type: 'ON_INPUT_CHANGE', payload: suggestionValue });
      return;
    }

    if (suggestion.position?.lat && suggestion.position?.lon) {
      params.lat = suggestion.position.lat;
      params.lon = suggestion.position.lon;
    }

    handleOnUpdateUserLocation(params, suggestionValue);
  };

  return {
    suggestions: state.suggestions,
    storedSuggestions: state.storedSuggestions,
    location: state.locationValue,
    isFetchingUserPosition: state.isFetchingUserPosition,
    showSuggestions: state.showSuggestions,
    onChange: handleOnChange,
    onBlur: handleOnBlur,
    onFocus: handleOnFocus,
    onKeyDown: handleOnKeyDown,
    onSuggestionsFetchRequested: handleOnSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleOnSuggestionsClearRequested,
    onSuggestionSelected: handleOnSuggestionSelected,
    onClearHistorySuggestion: handleOnClearHistorySuggestion,
    onFetchAndSetUserLocation: handleOnFetchAndSetUserLocation,
  };
};

export default useLocationAutoSuggestManager;
