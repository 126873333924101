import { __ } from './../locale';
const configIni = require('./../conf.json');
const {
  login,
  baseUrl,
  lastMinuteBaseUrl,
  serverUrl,
  authUrl,
  merchantUrl,
  ga, // should be deprecated in july when ga is deprecated
  ga4,
  lastMinuteGa, // should be deprecated in july when ga is deprecated
  lastMinuteGa4,
  googleOptimize,
  hotjar,
  env,
  sentry,
  adyenClientKey,
  giftcardAdyenClientKey,
  googlepay,
  applepay,
  waitlistModalKochavaLink,
  amplitudeAnalytics,
  amplitudeAnalyticsSistaminuten,
  amplitudeExperiment,
  amplitudeExperimentSistaminuten,
  cookieDomain,
  sistaMinutenCookieDomain,
  cypressProjectId,
  turnstileSiteKey,
  mapTiler,
} = configIni || {};
const { google, facebook } = login || {};

export const config = {
  GOOGLE_APP_ID: google || '255130626289-nti3iami5mf24m0r5snma1k78dgel870.apps.googleusercontent.com',
  FACEBOOK_APP_ID: facebook || '505704776482221',
  weekDaysShort: [__('sun'), __('mon'), __('tue'), __('wed'), __('thu'), __('fri'), __('sat')],
  weekDaysLong: [
    __('sunday'),
    __('monday'),
    __('tuesday'),
    __('wednesday'),
    __('thursday'),
    __('friday'),
    __('saturday'),
  ],
  monthsShort: [
    __('jan'),
    __('feb'),
    __('mar'),
    __('apr'),
    __('may'),
    __('jun'),
    __('jul'),
    __('aug'),
    __('sep'),
    __('oct'),
    __('nov'),
    __('dec'),
  ],
  monthsLong: [
    __('january'),
    __('february'),
    __('march'),
    __('april'),
    __('may'),
    __('june'),
    __('july'),
    __('august'),
    __('september'),
    __('october'),
    __('november'),
    __('december'),
  ],
  baseUrl: baseUrl || 'http://localhost:3000/',
  serverUrl: serverUrl || 'http://localhost:3001/',
  lastMinuteBaseUrl: lastMinuteBaseUrl || 'http://localhost:3004/',
  lastMinuteServerUrl: lastMinuteBaseUrl ? lastMinuteBaseUrl + 'api/' : 'http://localhost:3001/',
  authUrl: authUrl || 'https://konto.bddev.se/',
  merchantUrl: merchantUrl || 'https://kund.bddev.se',
  landingPath: 'places',
  portalPath: 'portal',
  ga: ga || 'UA-483672-46', // 46 - dev
  ga4: ga4 || 'G-ZBEXHZ05FG', // G-ZBEXHZ05FG - dev, G-JREQRCMRGN - prod
  lastMinuteGa: lastMinuteGa || 'UA-483672-46', // 46 - dev
  lastMinuteGa4: lastMinuteGa4 || 'G-ZBEXHZ05FG', // G-ZBEXHZ05FG - dev, G-P7T09R7674 - prod
  googleOptimize: googleOptimize || '', // OPT-T488TCX is dev
  facebookPixel: '847860578602702',
  lastMinuteFacebookPixel: '638678019649214',
  tikTokPixel: 'CKF7C0JC77U1O76TD4IG',
  lastMinuteTikTokPixel: 'CKIHVPRC77UANPJ93FN0',
  hotjar: hotjar || '1014658', // !!!! 1014658 is dev, 1014642 is live
  env: env || 'production',
  sentry: sentry || undefined,
  wootric: 'NPS-3f0bfe32',
  adyenClientKey,
  giftcardAdyenClientKey: giftcardAdyenClientKey || 'test_SK3SZVZCXVC7ZBWRX5DK6CWUAIPJHEUH',
  googlepay,
  applepay,
  waitlistModalKochavaLink,
  amplitudeAnalytics,
  amplitudeAnalyticsSistaminuten,
  amplitudeExperiment,
  amplitudeExperimentSistaminuten,
  customerIOSiteId: env === 'dev' ? 'f3de5ab6c43fd01f53fd' : '9c01c579520fa1afb7d9', // live or sandbox siteId
  cookieDomain,
  sistaMinutenCookieDomain,
  cypressProjectId,
  turnstileSiteKey,
  mapTiler: {
    mapId: mapTiler?.mapId || '0195b2aa-8153-7c42-8641-f3f02c490ff2',
    apiKey: mapTiler?.apiKey || 'PIyuOAbDVtJkWC2mOJVP',
  }
};
