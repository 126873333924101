import { bookActions } from '@/actions';
import {
  CheckoutDetailsBundle,
  CheckoutDetailsGiftcard,
  CheckoutDetailsProps,
  CheckoutDetailsService,
} from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { isGroupBooking as checkIfGroupBooking } from '@/helpers';
import { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { bookServices as oldBookServices } from '@/services';
import { CheckoutTracking } from '@/types/analytics';
import { ConfirmedBooking } from '@/types/api/services/booking';
import { Employee } from '@/types/api/services/booking/schema';
import { ClientBundle } from '@/types/api/services/users/schema';
import { SelectedPaymentMethod } from '@/types/checkout';
import { Giftcards } from '@/types/state/book';
import moment from 'moment';
import {
  amplitudeLogRevenue,
  analytics4TrackRevenue,
  facebookTrackRevenue,
  salonTrackEventPurchase,
  trackEventTikTok,
  trackMpAnalyticsEvent,
  trackMpEvent,
  trackPageCio,
} from './analytics';
import { getGiftcardTypeLabel } from './checkout';
import { getHoursInterval, makeReadableDay } from './date';
import { isEmpty } from './objects';
import {
  getDynamicPriceListIdKey,
  getOfferPrice,
  getPlaceTimezone,
  getServiceCampaignIfAny,
  getServiceDuration,
  getServicePrice,
  getTotalPrice,
  shouldShowFrom,
} from './placeHelper';

export function getBookingEmployee(booking: ConfirmedBooking, fromBookAgain: boolean = false): Employee {
  const anyEmployee = Boolean(booking?.extra?.anyEmployee);
  const anyEmployeeSelected = fromBookAgain && Boolean(booking?.extra?.anyEmployeeSelected);

  return anyEmployee || anyEmployeeSelected
    ? { id: 0, about: { name: _s('anyEmployee') } }
    : booking.services[0]?.employee || { id: 0, about: { name: _s('anyEmployee') } };
}

function getBookingDateTime(booking: ConfirmedBooking): { date: string; time: string } {
  const timezone = getPlaceTimezone(booking.place);
  const date = makeReadableDay(booking.start * 1000, timezone);
  const time = getHoursInterval(booking.start * 1000, 0, timezone);
  return {
    date,
    time,
  };
}

export function mapConfirmedBookingInfoGiftcards(
  booking: ConfirmedBooking,
  _addedGiftcards?: Giftcards,
  onRemoveGiftcard?: (code: string) => () => void,
): CheckoutDetailsGiftcard[] {
  const existingGiftcards: CheckoutDetailsGiftcard[] = booking.extra?.giftcards?.map((giftcard) => {
    const { code, amount } = giftcard;
    return {
      code,
      amount: `-${amount / 100} kr`,
      label: getGiftcardTypeLabel(giftcard),
      expiryDate: moment(giftcard.giftCardExpiryDate).format('YYYY-MM-DD'),
    };
  });

  const addedGiftcards: CheckoutDetailsGiftcard[] = Object.values(_addedGiftcards ?? {}).map((giftcard) => {
    const { code, amount } = giftcard;
    return {
      code,
      amount: `-${amount / 100} kr`,
      label: getGiftcardTypeLabel(giftcard),
      expiryDate: moment(giftcard.giftCardExpiryDate).format('YYYY-MM-DD'),
      ...(onRemoveGiftcard ? { removeGiftcard: onRemoveGiftcard(code) } : {}),
    };
  });

  const totalGiftcards = [...(existingGiftcards ?? []), ...(addedGiftcards ?? [])];

  return totalGiftcards;
}

export function mapConfirmedBookingInfoServices(
  booking: ConfirmedBooking,
  hideBundleUsages?: boolean,
): CheckoutDetailsService[] {
  const { place, extra } = booking;
  const { campaigns, appliedBundle } = extra;

  const employee = getBookingEmployee(booking);
  const { groupBooking, bookingTitle } = checkIfGroupBooking(booking, true, true);
  const isGroupBooking = groupBooking > 0;

  const priceListId = employee && employee.about && employee.about.priceListId ? employee.about.priceListId : 0;

  const serviceRows = booking?.services?.map((service, index) => {
    if (isGroupBooking && index > 0) return undefined;

    const duration = getServiceDuration(service.service, priceListId, 1);
    const name = isGroupBooking ? bookingTitle : service.service.name;

    if (appliedBundle && appliedBundle.service?.serviceId === service.service.id) {
      return {
        name,
        price: '0 kr',
        duration: hideBundleUsages
          ? duration
          : _s('bundleCheckoutUsageCount', {
              usages: appliedBundle.service.usages,
              quantity: appliedBundle.service.quantity,
            }),
      };
    }

    const { campaignService } = getServiceCampaignIfAny(service.service, campaigns);
    const price = getServicePrice(service.service, priceListId, place, null, getDynamicPriceListIdKey(booking));

    const offerPrice = getServicePrice(
      service.service,
      priceListId,
      place,
      campaignService,
      getDynamicPriceListIdKey(booking),
    );

    const discount = (() => {
      if (offerPrice === price) return null;
      return getOfferPrice(price, campaignService);
    })();

    return { name, price, duration, discount: discount ? `${discount} kr` : null };
  });

  return serviceRows.filter(Boolean);
}

export function mapConfirmedBookingToCheckoutDetailsProps({
  booking,
  selectedPaymentMethod,
  addedGiftcards,
}: {
  booking: ConfirmedBooking;
  selectedPaymentMethod?: SelectedPaymentMethod;
  addedGiftcards?: Giftcards;
}): CheckoutDetailsProps {
  const { place, extra, appliedBundle } = booking;
  const { campaigns, giftcards } = extra || {};
  const { date, time } = getBookingDateTime(booking);
  const totalGiftcards = { ...giftcards, ...(addedGiftcards || {}) };

  const placeName = place.about.name;
  const information = '';
  const logo = place.about?.profileImage
    ? place.about.profileImage
    : place.about?.images?.length > 0
    ? place.about?.images?.[0]
    : null;
  const dateTime = `${date} ${_s('at')} ${time}`;

  const employee = getBookingEmployee(booking);

  const total = getTotalPrice(
    booking.services,
    place,
    employee.about?.priceListId,
    totalGiftcards || {},
    0,
    1,
    true,
    campaigns || [],
    false,
    getDynamicPriceListIdKey(booking),
    appliedBundle,
  );
  const paymentMethod = selectedPaymentMethod ?? {
    type: booking.extra.paymentMethod,
    ...(booking.extra.paymentMethod === 4 && {
      brand: booking.extra?.onlinePayment?.method ?? 'unknown',
      lastFour: booking.extra.onlinePayment?.lastFour ?? '',
      id: '',
    }),
  };

  const isFromPrice = shouldShowFrom(booking.services, place);
  const isPriceVariable = isFromPrice && total !== _s('variablePrice') && total !== _s('freePrice');
  const totalLabel = isPriceVariable ? _s('from') : _s('total');
  const priceToPay = { label: totalLabel, price: `${total}` };

  /**
   * We do not want to show payment method section if the only service in the booking is paid with bundle
   */
  const showPaymentMethod = (() => {
    if (!appliedBundle) return true;
    const bundleBookingIncludesAddons = booking.services.some(
      (service) => service.service.id !== appliedBundle.service.serviceId,
    );
    return bundleBookingIncludesAddons;
  })();

  return {
    dateTime,
    information,
    logo,
    performer: employee.about.name,
    placeName,
    priceToPay,
    paymentMethod: showPaymentMethod ? paymentMethod : undefined,
  };
}

export function mapConfirmedBundleToCheckoutDetailsProps({
  bundle,
  selectedPaymentMethod,
}: {
  bundle: ClientBundle;
  selectedPaymentMethod?: SelectedPaymentMethod;
}): CheckoutDetailsProps {
  const totalLabel = _s('total');
  const priceToPay = { label: totalLabel, price: bundle.finalPriceLabel ?? bundle.priceLabel };

  const paymentMethod = ((): SelectedPaymentMethod => {
    if (selectedPaymentMethod) return selectedPaymentMethod;

    switch (bundle.paymentMethod.type) {
      case 'Qliro':
        return { type: CHECKOUT_PAYMENT_METHOD.QLIRO };
      case 'Swish':
        return { type: CHECKOUT_PAYMENT_METHOD.SWISH };
      case 'ApplePay':
        return { type: CHECKOUT_PAYMENT_METHOD.APPLE_PAY };
      case 'GooglePay':
        return { type: CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY };
      case 'StoredCard':
        return {
          type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
          brand: bundle.paymentMethod?.cardDetails?.brand?.id ?? 'unknown',
          lastFour: bundle.paymentMethod.cardDetails?.lastFour ?? '',
          id: '',
        };
    }
  })();

  return {
    logo: bundle.place.about.profileImage,
    performer: _s('anyEmployee'),
    placeName: bundle.place.about.name,
    bundles: mapConfirmedBundleToCheckoutDetailsBundles(bundle),
    paymentMethod,
    priceToPay,
    information: '',
    dateTime: '',
  };
}

function mapConfirmedBundleToCheckoutDetailsBundles(bundle: ClientBundle): CheckoutDetailsBundle[] {
  const bundles = ((): CheckoutDetailsBundle[] => {
    return [
      {
        name: bundle.name,
        price: `${bundle.priceLabel}`,
        discount: bundle.finalPriceLabel,
        quantity: `(${_s('buyBundles.quantity', { count: bundle.service.quantity })})`,
        validity: _s('buyBundles.valid', { validity: _s('months', { count: bundle.validMonths }) }),
      },
    ];
  })();

  return bundles;
}

async function getBookingCategory(booking: ConfirmedBooking) {
  try {
    const { services } = booking;
    if (services && services[0] && services[0]?.service?.about?.product) {
      const response = await oldBookServices.getBookingCategory(services[0].service.about.product);
      const { product } = response || {};
      if (product && product.productFamily) {
        return product.productFamily;
      }
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
}

export async function getBookingTrackingProps(booking: ConfirmedBooking): Promise<CheckoutTracking.Booking> {
  const { id: bookingId } = booking;
  const { transactionProps, ...bookingTrackingProps } = bookActions.getTrackingProps() || {};

  if (isEmpty(bookingTrackingProps)) return null;

  if (bookingId) {
    bookingTrackingProps['booking_id'] = bookingId;
    transactionProps.orderId = bookingId;
  }

  bookingTrackingProps['service_category'] = await getBookingCategory(booking);
  return { transactionProps, ...bookingTrackingProps };
}

export async function sendBookingConfirmationSuccessEvent(_bookingTrackingProps: CheckoutTracking.Booking) {
  const { transactionProps, ...bookingTrackingProps } = _bookingTrackingProps;
  const { paymentMethodsProps, bookingProps, booking_id } = bookingTrackingProps;

  trackScreenView({
    name: 'screen_view_checkout_booking_confirmation',
    properties: {
      ...bookingTrackingProps.bookingProps,
      ...bookingTrackingProps.paymentMethodsProps,
      ...bookingTrackingProps.extraProps,
    },
  });

  trackMpEvent('checkout_success', {
    ...bookingTrackingProps.bookingProps,
    ...bookingTrackingProps.paymentMethodsProps,
    ...bookingTrackingProps.extraProps,
    checkout_type: 'booking',
  });

  trackMpAnalyticsEvent('booking_success', {
    booking_source: 'marketplace',
    value: transactionProps?.value || transactionProps?.hiddenValue || 0,
    currency: transactionProps?.currency,
    transaction_id: booking_id,
  });

  amplitudeLogRevenue({ ...transactionProps });

  if (transactionProps?.allowMarketing) {
    facebookTrackRevenue({ ...transactionProps });

    analytics4TrackRevenue({ ...transactionProps });

    trackPageCio('booking_confirmation', {
      booking_id,
      merchant_id: transactionProps?.place?.id,
      merchant_name: transactionProps?.place?.name,
      service_name: transactionProps?.contentName,
      service_category: bookingTrackingProps?.service_category,
    });

    trackEventTikTok('BookingConfirmed', {
      value: transactionProps?.value || transactionProps?.hiddenValue || 0, // number. Value of the order or items sold. Example: 100.
      currency: transactionProps?.currency, // string. The 4217 currency code. Example: 'USD'.
      contents: (transactionProps?.items || []).map((item) => ({
        content_id: item.id, // string. ID of the product. Example: '1077218'.
        content_type: 'product', // string. Either product or product_group.
        content_name: item.name, // string. The name of the page or product. Example: 'shirt'.
      })),
      content_type: 'product',
      description: transactionProps?.description, // string. The description of the item or page. Example: "light weight cotton".
    });

    trackEventTikTok('PlaceAnOrder', {
      value: transactionProps?.value || transactionProps?.hiddenValue || 0, // number. Value of the order or items sold. Example: 100.
      currency: transactionProps?.currency, // string. The 4217 currency code. Example: 'USD'.
      contents: (transactionProps?.items || []).map((item) => ({
        content_id: item.id, // string. ID of the product. Example: '1077218'.
        content_type: 'product', // string. Either product or product_group.
        content_name: item.name, // string. The name of the page or product. Example: 'shirt'.
      })),
      content_type: 'product',
      description: transactionProps?.description, // string. The description of the item or page. Example: "light weight cotton".
    });
  }

  if (transactionProps?.place?.about) {
    salonTrackEventPurchase({ ...transactionProps });
  }
}
